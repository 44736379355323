import React, { useState, useEffect } from "react";
import {
  postWithAuth,
  getCurrentUser,
} from "../../../../../firebase/authentication/auth";
import "./ShareDisclosureAnalysisButton.css";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const DEFAULT_USER = process.env.REACT_APP_DEFAULT_USER as string;

export default function ShareDisclosureAnalysisButton(props: {
  uid: string | null;
  disclosure_id: string | null;
  share_link: string | null;
}): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);

  const showCopiedBubble = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const generateShareLink = async () => {
      const { uid, disclosure_id, share_link } = props;

      if (share_link) {
        setGeneratedLink(share_link);
        return;
      }

      let userId = DEFAULT_USER;

      // if (!uid || !disclosure_id) {
      //   const currentUser = await getCurrentUser();
      // }

      if (!disclosure_id) {
        console.error("Unable to determine disclosure ID.");
        return;
      }

      try {
        const result = await fetch(
          `${HTTP_PROTOCAL}://` +
            process.env.REACT_APP_DISCLOSURE_ANALYSIS_API +
            `/api/disclosure/share/${DEFAULT_USER}/${disclosure_id}`
        );

        const resultURL = await result.json();

        if (resultURL.url) {
          setGeneratedLink(resultURL.url);
        } else {
          console.error("Failed to generate a shareable link.");
        }
      } catch (error) {
        console.error("Error generating share link:", error);
      }
    };

    generateShareLink();
  }, [props]);

  const handleClick = async () => {
    if (!generatedLink) {
      alert("Link is not available yet. Please try again later.");
      return;
    }

    try {
      await navigator.clipboard.writeText(generatedLink);
      showCopiedBubble();
    } catch (error) {
      console.error("Error copying to clipboard:", error);
      alert("Failed to copy link to clipboard.");
    }
  };

  return (
    <div className="share-disclosure-analysis-button" onClick={handleClick}>
      Share
      <div
        className={`${
          isCopied
            ? "share-disclosure-analysis-button-coming-copied"
            : "share-disclosure-analysis-button-coming-not"
        }`}
      >
        Link copied to clipboard
      </div>
    </div>
  );
}
