/* React */
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

/* Styling */
import "./PackageInfo.css";
import TextEditIcon from "../../../../../assets/text-edit.png";

import { getCurrentUser } from "../../../../../firebase/authentication/auth";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/*
 * Rename a custom analysis
 */
const renamePackageEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/package/rename`;
export async function renamePackage(disclosureID: string, newName: string) {
  // Get user id
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();
  const uid = user?.uid;

  await fetch(`${renamePackageEndpoint}/${uid}/${disclosureID}/${newName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
}
function PackageInfo({
  propertyName,
  disclosureID,
}: {
  propertyName: string;
  disclosureID: string;
}): JSX.Element {
  const [newPropertyName, setNewPropertyName] = useState(propertyName);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNewPropertyName(propertyName);
  }, [propertyName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPropertyName(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      renamePackage(disclosureID, newPropertyName);
    }
  };

  const toggleEditing = () => {
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [isEditing, newPropertyName]);

  return (
    <div className="package-info">
      <div className="package-info-name">
        {isEditing ? (
          <input
            ref={inputRef}
            className="package-info-property-name"
            value={newPropertyName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        ) : (
          <div className="package-info-property-name" onClick={toggleEditing}>
            {newPropertyName}
          </div>
        )}

        {!isEditing && (
          <img
            className="package-info-property-name-edit"
            src={TextEditIcon}
            alt="Property name edit button."
            onClick={toggleEditing}
          />
        )}
      </div>
      <div className="package-info-directory">
        <br />
        {/*
        <Link to="/dashboard" className="package-info-directory-disclosures">
          Disclosures
        </Link>
        
        <div className="package-info-directory-arrow">&gt;</div>
        
        <div className="package-info-directory-property-name">
          {newPropertyName}
        </div>
        */}
      </div>
    </div>
  );
}

export default PackageInfo;
