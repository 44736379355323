import React, { useEffect, useState } from "react";
import Nav from "../Nav/Nav";
import agentImage from "../../../../src/assets/agent.svg"; // Adjust path to your image
import HomeGraphic from "../../../../src/assets/home-graphic-pivot.svg";

import "./Landing.css";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../firebase/firebase";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const endpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/benchmarking/ai-agent-interest/submit-email`;

export async function addToEmailDatabase(email: string) {
  const formData = new FormData();
  formData.append("email", email);

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to submit email.");
    }
  } catch (error) {
    console.error("Error submitting email:", error);
    throw error;
  }
}

export function Landing() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false); // State for managing the popup visibility
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email.match(emailPattern)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      await addToEmailDatabase(email);
      setShowModal(true); // Show success modal on successful API call
      setEmail(""); // Clear input field
    } catch {
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal when the user clicks the close button
    setEmail("");
  };

  useEffect(() => {
    if (process.env.REACT_APP_SSL_ENABLED === "true") {
      logEvent(firebaseAnalytics, "ai_agent_landing_page_hit");
    }
  }, []);

  return (
    <>
      <div className="landing-container">
        <Nav />
        <div className="landing-content">
          <div className="landing-text">
            <h1 className="landing-title">
              AI Real Estate Agent that
              <span style={{ color: "#59CB90" }}> pays you $60,000*</span> when
              <br />
              you buy.
            </h1>
            <p className="landing-subtitle">
              Coming soon to the Silicon Valley buyers market.
            </p>
            <div className="email-waitlist">
              <form onSubmit={handleSubmit} className="waitlist-form">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Thinking of buying soon? Enter email!"
                  className="email-input"
                  required
                />
                <button
                  type="submit"
                  className="join-waitlist-button"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Join Waitlist"}
                </button>
              </form>
            </div>
          </div>
          <div className="landing-image">
            <img src={agentImage} alt="Real Estate Agent" />
          </div>
        </div>
        <div className="fine-print">
          <p>
            *Based on a 2024 average home purchase price of $2,398,708.40 in
            Silicon Valley. Agnsy pays you around 2.5% of the home value, coming
            from buyer agent fees.
          </p>
        </div>
        <div className="founders-video" id="about-us">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1Za-vehRQjM?si=p2QHCV5ty6UOglqG"
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
        <br />

        <div
          style={{ color: "white", fontWeight: "300", paddingBottom: "30px" }}
        >
          team@agnsy.ai
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="home-graphic">
          <img src={HomeGraphic} alt="Home Graphic" />
        </div>

        {/* Thank You Modal */}
        {showModal && (
          <div className="thank-you-modal">
            <div className="modal-content">
              <h2>Thank you for signing up!</h2>
              <p>
                You're now on the waitlist. We'll notify you when we launch!
              </p>
              <button onClick={closeModal} className="close-modal-btn">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      <br />

      <br />
      <br />
    </>
  );
}
