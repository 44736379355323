/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */
import DropZone from "./DropZone";

/* Functions and Utils */
import { startAnalysis } from "./api";
import { withRequirements } from "../../Requirements";
import { firebaseAnalytics } from "../../../firebase/firebase";
import { logEvent } from "firebase/analytics";

/* Styling */
import "./DisclosureUpload.css";
import LoadingSpinner from "../../Common/Spinner/LoadingSpinner";
import { DemoDisclosureViewer } from "../Landing/DemoDisclosureViewer/DemoDisclosureViewer";
import Navbar from "../../Common/Navbar";

function DisclosureUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [showDemo, setShowDemoInternal] = useState(false);
  const navigate = useNavigate();

  const setShowDemo = (toggle: boolean) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const scrollDuration = 400;

    setTimeout(() => {
      setShowDemoInternal(toggle);
    }, scrollDuration);
  };

  /*
  =============================================================
  STATE
  =============================================================
  */

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (selectedFile) {
      const zipMimeTypes = [
        "application/zip", // Standard ZIP MIME type
        "application/x-zip-compressed", // Windows-specific ZIP MIME type
        "multipart/x-zip", // Another variant sometimes seen
        "application/x-compressed", // Older variant for compressed files
      ];

      if (!zipMimeTypes.includes(selectedFile.type)) {
        alert("Only ZIP files are accepted.");
        setSelectedFile(null);
        return;
      }

      setUploadingFile(true); // Show spinner
      startAnalysis(selectedFile, (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setUploadProgress(percentCompleted);
        }
      })
        .then((disclosureId) => {
          navigate(`/disclosure/analysis?disclosure=${disclosureId}`);
          if (process.env.REACT_APP_SSL_ENABLED === "true") {
            logEvent(firebaseAnalytics, "disclosure_package_uploaded");
          }
        })
        .catch((error) => {
          alert(
            "Error starting analysis: " + (error.message || error.toString())
          );
          console.error("Error starting analysis:", error);
          setSelectedFile(null);
          setUploadingFile(false); // Hide spinner and show DropZone again
          setUploadProgress(0); // Reset progress
        });
    }
  }, [selectedFile, navigate]);

  useEffect(() => {
    logEvent(firebaseAnalytics, "disclosure_upload_page_hit");
  }, []);

  /*

  =============================================================
  RENDERING
  =============================================================
  */
  return (
    <div>
      <Navbar />
      <div className="disclosure-upload-page-title">Disclosure Analysis</div>
      <div className="disclosure-upload-page-subtitle">
        Generate a disclosure analysis in minutes
      </div>
      {uploadingFile ? (
        <div className="wrapper-loader">
          <div className="loading-spinner loading-spinner--large">
            <LoadingSpinner />
          </div>
          <p className="wrapper-loader__loading">{uploadProgress}%</p>
        </div>
      ) : (
        <DropZone
          uploadingFile={uploadingFile}
          setUploadingFile={setUploadingFile}
          setSelectedFile={setSelectedFile}
        />
      )}

      <div style={{ paddingBottom: "50px" }}>
        <DemoDisclosureViewer showDemo={showDemo} setShowDemo={setShowDemo} />
      </div>
    </div>
  );
}

export default withRequirements(DisclosureUpload, []);
