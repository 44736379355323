import { Routes, Route, Navigate } from "react-router-dom";
// import Scroll from "./Scroll";
import { PrivateAnalysis, PublicAnalysis } from "../Pages/DisclosureAnalysis";
import DisclosureUpload from "../Pages/DisclosureUpload";
import FAQ from "../Pages/FAQ/FAQ";
// import LogIn from "../Pages/Auth/LogIn";
// import Paywall from "../Pages/Paywall";
// import Signup from "../Pages/Auth/Signup";
// import NotFound from "../Pages/NotFound";
// import VerifyPhone from "../Pages/Auth/VerifyPhoneVerificationCode/VerifyPhoneVerificationCode";
// import Dashboard from "../Pages/Dashboard/Dashboard";
import { Landing } from "../Pages/Landing/Landing";
// import TermsOfService from "../Pages/Legal/TermsOfServices";
// import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy";
// import SendPhoneVerificationCode from "../Pages/Auth/SendPhoneVerificationCode/SendPhoneVerificationCode";
// import ForgotUsername from "../Pages/Auth/ForgotUsername/ForgotUsername";
// import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
// import AdminPage from "../Pages/Admin/AdminPage";
// import BenchmarkingDashboard from "../Pages/BenchmarkingDashboard/BenchmarkingDashboard";
// import BenchmarkingScoringn from "../Pages/BenchmarkingScoring/BenchmarkingScoring";
// import ViewOCR from "../Pages/ViewOCR/ViewOCR";
import { Landing as LandingPivot } from "../PivotPages/Landing/Landing";
import LogIn from "../Pages/Auth/LogIn";
import NotFound from "../Pages/NotFound";

function NotFoundPage() {
  return (
    <div style={{ color: "white" }}>
      <h1 style={{ color: "white" }}>404 - Page Not Found</h1>
      <p style={{ color: "white" }}>
        The URL stays the same for debugging purposes.
      </p>
    </div>
  );
}

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/ai/agent" element={<LandingPivot />} />
        <Route path="/disclosure/upload" element={<DisclosureUpload />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/disclosure/analysis/shared"
          element={<PublicAnalysis />}
        />
        <Route path="/disclosure/analysis" element={<PrivateAnalysis />} />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/login" element={<LogIn />} />

        {/*
        <Route path="/" element={<Landing />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        

        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/send-phone-verification-code"
          element={<SendPhoneVerificationCode />}
        />
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/forgot-username" element={<ForgotUsername />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/benchmarking" element={<BenchmarkingDashboard />} />
        <Route
          path="/admin/benchmarking/score"
          element={<BenchmarkingScoring />}
        />
        <Route path="/admin/benchmarking/view-ocr" element={<ViewOCR />} />

        <Route path="/purchase" element={<Paywall />} />
        <Route path="/return" element={<Navigate to="/404" replace />} />

       
        <Route path="/404" element={<NotFound />} />
          */}
      </Routes>
    </div>
  );
}

export default App;
