/* React */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/* UI Components */
import Navbar from "../../Common/Navbar";
import DisclosureAnalysisViewer from "./DisclosureViewer/DisclosureViewer";

/* Data Models */
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";
import { PDFFile } from "../../../model/DisclosureAnalysis/PDFFile";
import { PDFReferenceFirestore } from "../../../model/DisclosureAnalysis/PDFReferenceFirestore";

/* Functions and Utils */
import { openDB } from "idb";
import {
  getDisclosureWebSocketForCurrentUser,
  fetchPdfFile,
  getDisclosureWebSocketFromLink,
  getDisclosureWebSocket,
} from "./api/";
import PDFCache from "../../../utils/cache/pdf";
import {
  withRequirements,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  userSubscribedOrTrialedRequirement,
} from "../../Requirements";

/* Styling */
import "./DisclosureAnalysis.css";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../firebase/firebase";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const endpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/benchmarking/disclosure-analysis-usage/submit-email`;

export async function addToEmailDatabase(email: string) {
  const formData = new FormData();
  formData.append("email", email);

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to submit email.");
    }
  } catch (error) {
    console.error("Error submitting email:", error);
    throw error;
  }
}

function DisclosureAnalysis(): JSX.Element {
  /**
   * Constants
   */
  const disclosureId = new URLSearchParams(useLocation().search).get(
    "disclosure"
  );
  let uid = new URLSearchParams(useLocation().search).get("uid");
  const link = new URLSearchParams(useLocation().search).get("link");
  const navigate = useNavigate();
  const cacheRef = React.useRef<PDFCache | null>(null);

  /**
   * State Variables
   */
  const [disclosurePackage, setDisclosurePackage] =
    useState<DisclosurePackage | null>(null);
  const [pdfReferences, setPdfReferences] = useState<PDFReferenceFirestore[]>(
    []
  );
  const [pdfFiles, setPdfFiles] = useState<PDFFile[]>([]);
  const [pdfCache, setPdfCache] = useState<PDFCache | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * Functionality
   */

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email.match(emailPattern)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      await addToEmailDatabase(email);
      localStorage.setItem("emailSubmitted", "true");
      setShowPopup(false);
      setEmail("");
      if (process.env.REACT_APP_SSL_ENABLED === "true") {
        logEvent(firebaseAnalytics, "disclosure_analysis_user", {
          user_email: email,
        });
      }
    } catch {
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * API Calls
   */
  const getPDFs = async (pdfs: PDFReferenceFirestore[]) => {
    if (pdfCache) {
      const PDFsArray = await Promise.all(
        pdfs.map(async (pdf) => {
          const cachedPDF = await pdfCache.get(pdf.file_id);
          if (cachedPDF) {
            return cachedPDF;
          } else {
            const fetchedPDF = await fetchPdfFile(pdf);
            await pdfCache.store(fetchedPDF, pdf.file_id);
            return fetchedPDF;
          }
        })
      );

      setPdfFiles(PDFsArray);
    } else {
      console.warn("No PDF Cache setup");
    }
  };

  async function disclosureWebsocket(
    uid: string | null,
    disclosureId: string | null,
    link: string | null
  ): Promise<WebSocket | null> {
    let ws: WebSocket | null;

    // WebSocket initialization logic...
    if (link) {
      ws = await getDisclosureWebSocketFromLink(link);
    } else if (uid) {
      if (!disclosureId) {
        navigate("/");
        return null;
      }
      ws = await getDisclosureWebSocket(uid, disclosureId, null);
    } else {
      ws = await getDisclosureWebSocketForCurrentUser(disclosureId);
    }

    if (!ws) {
      navigate("/");
      return null;
    }

    let pdfReferencesWS: PDFReferenceFirestore[] = [];

    ws.onopen = () => {
      console.log("WebSocket opened");
    };

    ws.onmessage = async (event) => {
      const disclosurePackageResponse: DisclosurePackage = JSON.parse(
        JSON.parse(event.data)
      );
      setDisclosurePackage(disclosurePackageResponse);

      if (
        JSON.stringify(disclosurePackageResponse.files) !==
        JSON.stringify(pdfReferencesWS)
      ) {
        pdfReferencesWS = disclosurePackageResponse.files;
        setPdfReferences(disclosurePackageResponse.files);
      }
    };

    ws.onerror = (error) => {
      console.error(error);
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
    };

    return ws; // Return the WebSocket instance
  }
  /**
   * Hooks
   */
  useEffect(() => {
    let ws: WebSocket | null = null;

    async function initializePDFCache() {
      const db = await openDB("DisclosureAnalysis", 1, {
        upgrade(db) {
          db.createObjectStore("pdfs");
        },
      });
      setPdfCache(new PDFCache(db));
    }

    initializePDFCache();
    disclosureWebsocket(uid, disclosureId, link).then((webSocket) => {
      ws = webSocket;
    });

    return () => {
      if (ws) {
        console.log("Closing WebSocket...");
        ws.close();
      }

      // Close PDFCache
      if (cacheRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cacheRef.current.close("DisclosureAnalysis");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const emailSubmitted = localStorage.getItem("emailSubmitted");

    if (process.env.REACT_APP_SSL_ENABLED === "true") {
      logEvent(firebaseAnalytics, "disclosure_analysis_page_hit");
    }

    if (!emailSubmitted) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 45 * 1000);

      return () => clearTimeout(timer);
    } else {
      if (process.env.REACT_APP_SSL_ENABLED === "true") {
        logEvent(firebaseAnalytics, "disclosure_analysis_user", {
          user_email: emailSubmitted,
        });
        logEvent(firebaseAnalytics, "disclosure_analysis_return_user", {
          user_email: emailSubmitted,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (pdfReferences.length > 0) {
      getPDFs(pdfReferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfReferences]);

  /**
   * Rendering
   */
  if (pdfCache === null) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Navbar />
      <DisclosureAnalysisViewer
        disclosurePackage={disclosurePackage}
        pdfFiles={pdfFiles}
        pdfCache={pdfCache}
        disclosureId={disclosureId}
        uid={uid}
        link={link}
      />
      {showPopup && (
        <div className="disclosure-analysis-popup">
          <div className="disclosure-analysis-popup-content">
            Glad you are liking the product! <br />
            Enter email to continue viewing.
            <form
              onSubmit={handleSubmit}
              className="disclosure-analysis-popup-form"
            >
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="example@gmail.com"
                className="disclosure-analysis-email-input"
                required
              />
              <button
                type="submit"
                className="disclosure-analysis-popup-button"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

function PrivateAnalysis(): JSX.Element {
  return React.createElement(DisclosureAnalysis);
}

function PublicAnalysis(): JSX.Element {
  return React.createElement(DisclosureAnalysis);
}

export { PrivateAnalysis, PublicAnalysis };
