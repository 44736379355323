import React, { useEffect, useState } from "react";
import NavbarAuthorized from "./NavbarAuthorized";
import NavbarUnauthorized from "./NavbarUnauthorized";
import { Auth } from "../../../firebase/authentication/auth";
import { User } from "firebase/auth";

export default function Navbar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return <NavbarUnauthorized />;

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((authUser: User | null) => {
      const userAuthenticated = !!authUser;
      setIsAuthenticated(userAuthenticated);
      localStorage.setItem("isAuthenticated", userAuthenticated.toString());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return isAuthenticated ? <NavbarAuthorized /> : <NavbarUnauthorized />;
}
