/* React */
import React, { useEffect, useState, useCallback } from "react";

/* UI Components */
import ShareDisclosureAnalysisButton from "./ShareDisclosureAnalysisButton";
import PackageInfo from "./PackageInfo";
import PDFViewer, { SelectedPage } from "./PDFViewer/PDFViewer";
import AnalysesNavViewer from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationAnalyses";
import DisclosureNavigationToggle from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationToggle";
import DisclosureNavigationFiles from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationFiles";
import DisclosureAnalysisShimmy from "./DisclosureViewerShimmy";
import { DisclosureNavigationSection } from "./../DisclosureViewer/DisclosureNavigation/DisclosureNavigationSection";
import AnalysisModuleViewer from "./AnalysisModuleViewer/AnalysisModuleViewer";

/* Data Models */
import { DisclosurePackage } from "../../../../model/DisclosureAnalysis/DisclosurePackage";
import { PDFFile } from "../../../../model/DisclosureAnalysis/PDFFile";
import { ClassifiedFile } from "../../../../model/DisclosureAnalysis/ClassifiedFile";
import {
  AnalysisModule,
  AnalysisModuleType,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/AnalysisModule";
import { ComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";

/* Functions and Utils */
import { fetchPdfFile } from "./../api/";
import PDFCache from "../../../../utils/cache/pdf";

/* Styling */
import "./DisclosureViewer.css";
import { CustomComponentAnalysisModule } from "../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";
import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

/* COMPONENT PROPS */
interface DisclosureAnalysisViewerProps {
  disclosurePackage: DisclosurePackage | null;
  pdfFiles: PDFFile[];
  pdfCache: PDFCache;
  disclosureId: string | null;
  uid: string | null;
  link: string | null;
}

/* COMPONENT */
export function DisclosureAnalysisViewer({
  disclosurePackage,
  pdfFiles,
  pdfCache,
  disclosureId,
  uid,
  link,
}: DisclosureAnalysisViewerProps): JSX.Element {
  /**
   * State Variables
   */
  const [currentAnalysisModule, setCurrentAnalysisModule] =
    useState<AnalysisModule | null>(null);
  const [selectedNav, setSelectedNav] = useState<DisclosureNavigationSection>(
    DisclosureNavigationSection.ANALYSIS
  );
  const [pdfFilesToRender, setPdfFilesToRender] = useState<PDFFile[]>([]);
  const [selectedPage, setSelectedPage] = useState<SelectedPage | null>(null);
  const [isNavExpanded, setIsNavExpanded] = useState(true); // Default to true for desktop
  const [isDarkMode, setIsDarkMode] = useState(true); // Dark mode state

  /**
   * Utility Functions
   */
  const convertClassifiedFiletoPDFFile = useCallback(
    async (classifiedFiles?: ClassifiedFile[]): Promise<PDFFile[]> => {
      if (classifiedFiles && classifiedFiles.length > 0 && pdfCache) {
        const pdfs = await Promise.all(
          classifiedFiles.map(async (file) => {
            const cachedPDF = await pdfCache.get(file.pdf.file_id);
            if (cachedPDF) {
              return cachedPDF;
            } else {
              const fetchedPDF = await fetchPdfFile(file.pdf);
              await pdfCache.store(fetchedPDF, file.pdf.file_id);
              return fetchedPDF;
            }
          })
        );
        return pdfs;
      }
      return [];
    },
    [pdfCache]
  );

  /**
   * Type Guards
   */
  function isComponentAnalysisModule(
    module: AnalysisModule | null
  ): module is ComponentAnalysisModule {
    return module?.analysis_module_type === AnalysisModuleType.COMPONENT;
  }

  function isCustomComponentAnalysisModule(
    module: AnalysisModule | null
  ): module is CustomComponentAnalysisModule {
    return module?.analysis_module_type === AnalysisModuleType.CUSTOM_COMPONENT;
  }

  function isMasterReportModule(
    module: AnalysisModule | null
  ): module is AnalysisModule {
    return module?.analysis_module_type === AnalysisModuleType.MASTER_REPORT;
  }

  /**
   * Extract the status into a variable for the dependency array
   */
  const currentAnalysisModuleStatus =
    currentAnalysisModule && "status" in currentAnalysisModule
      ? currentAnalysisModule.status
      : undefined;

  /**
   * Hooks
   */

  useEffect(() => {
    if (disclosurePackage) {
      if (currentAnalysisModule === null) {
        setCurrentAnalysisModule(disclosurePackage.analysis.master);
      } else {
        let updatedModule: AnalysisModule | null = null;

        if (isComponentAnalysisModule(currentAnalysisModule)) {
          const components = Object.values(
            disclosurePackage.analysis.components
          );
          updatedModule =
            components.find(
              (comp) =>
                comp.id === currentAnalysisModule.id &&
                comp.type === currentAnalysisModule.type
            ) || null;
        } else if (isCustomComponentAnalysisModule(currentAnalysisModule)) {
          const customAnalyses = Object.values(
            disclosurePackage.analysis.custom_analyses
          );
          updatedModule =
            customAnalyses.find(
              (comp) => comp.id === currentAnalysisModule.id
            ) || null;
        } else if (isMasterReportModule(currentAnalysisModule)) {
          updatedModule = disclosurePackage.analysis.master;
        }

        if (updatedModule) {
          setCurrentAnalysisModule(updatedModule);
        }
      }
    }
  }, [disclosurePackage, currentAnalysisModule]);

  useEffect(() => {
    const updatePDFsToRender = async () => {
      if (currentAnalysisModule === null) {
        setPdfFilesToRender([]);
        setSelectedPage(null); // Reset selected page
        return;
      }

      let pdfs: PDFFile[] = [];

      // Base Component
      if (isComponentAnalysisModule(currentAnalysisModule)) {
        const pdfs = await convertClassifiedFiletoPDFFile(
          currentAnalysisModule?.associated_files
        );
        setPdfFilesToRender(pdfs);

        // Set selectedPage based on pages field
        if (
          currentAnalysisModule.associated_files &&
          currentAnalysisModule.associated_files.length > 0
        ) {
          const firstFile = currentAnalysisModule.associated_files[0];
          if (firstFile.pages && firstFile.pages.length > 0) {
            setSelectedPage({
              fileID: firstFile.pdf.file_id,
              page: firstFile.pages[0] + 1, // PDF pages are 1-indexed
            });
          } else {
            setSelectedPage(null);
          }
        } else {
          setSelectedPage(null);
        }
      }

      // Custom Component
      else if (isCustomComponentAnalysisModule(currentAnalysisModule)) {
        pdfs = await convertClassifiedFiletoPDFFile(
          currentAnalysisModule?.associated_files
        );
        setPdfFilesToRender(pdfs);

        // Set selectedPage based on pages field
        if (
          currentAnalysisModule.associated_files &&
          currentAnalysisModule.associated_files.length > 0
        ) {
          const firstFile = currentAnalysisModule.associated_files[0];
          if (firstFile.pages && firstFile.pages.length > 0) {
            setSelectedPage({
              fileID: firstFile.pdf.file_id,
              page: firstFile.pages[0] + 1,
            });
          } else {
            setSelectedPage(null);
          }
        } else {
          setSelectedPage(null);
        }
      } else if (isMasterReportModule(currentAnalysisModule)) {
        setPdfFilesToRender(pdfFiles);
        setSelectedPage(null);
      }
    };

    updatePDFsToRender();
  }, [
    currentAnalysisModule,
    currentAnalysisModuleStatus,
    pdfFiles,
    convertClassifiedFiletoPDFFile,
  ]);

  /**
   * Synchronize dark mode class with isDarkMode state
   */
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove("dark-mode");
    };
  }, [isDarkMode]);

  const toggleNav = () => {
    setIsNavExpanded((prevState) => !prevState);
  };

  const getCurrentModuleName = () => {
    if (isComponentAnalysisModule(currentAnalysisModule)) {
      return currentAnalysisModule.type;
    }
    if (isCustomComponentAnalysisModule(currentAnalysisModule)) {
      return currentAnalysisModule.analysis_module_type;
    }

    return selectedNav === DisclosureNavigationSection.ANALYSIS
      ? "MASTERREPORT"
      : "Files";
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  /**
   * Rendering
   */
  return (
    <div className="disclosure-viewer">
      {disclosurePackage ? (
        <>
          <div className="disclosure-viewer-header">
            <PackageInfo
              propertyName={disclosurePackage.property_name}
              disclosureID={disclosurePackage.id}
            />
            {/* Dark Mode Toggle Button */}

            <ShareDisclosureAnalysisButton
              uid={uid}
              disclosure_id={disclosureId}
              share_link={link ? window.location.href : null}
            />
          </div>
          <div className="disclosure-viewer-viewers">
            <div className="disclosure-viewer-viewers-analysis">
              <div
                className={`disclosure-viewer-navigation ${
                  isNavExpanded ? "expanded" : "collapsed"
                }`}
                onClick={toggleNav}
              >
                <div
                  className={`disclosure-viewer-selected-section ${getCurrentModuleName()}`}
                >
                  {getCurrentModuleName() === "MASTERREPORT"
                    ? "Master Report"
                    : ComponentTypeDisplayMapping[
                        getCurrentModuleName() as ComponentAnalysisType
                      ]}
                </div>
                <>
                  <DisclosureNavigationToggle
                    selected={selectedNav}
                    setSelected={setSelectedNav}
                  />
                  {selectedNav === DisclosureNavigationSection.ANALYSIS ? (
                    <AnalysesNavViewer
                      disclosureID={disclosurePackage.id}
                      masterReport={disclosurePackage.analysis.master}
                      components={Object.values(
                        disclosurePackage.analysis.components
                      )}
                      customAnalyses={Object.values(
                        disclosurePackage.analysis.custom_analyses
                      )}
                      currentAnalysisModule={currentAnalysisModule}
                      setCurrentAnalysisModule={setCurrentAnalysisModule}
                    />
                  ) : (
                    <DisclosureNavigationFiles pdfs={pdfFiles} />
                  )}
                </>
              </div>
              <AnalysisModuleViewerWrapper
                disclosurePackage={disclosurePackage}
                currentAnalysisModule={currentAnalysisModule}
                pdfCache={pdfCache}
                setSelectedPage={setSelectedPage}
              />
            </div>
            <PDFViewer pdfs={pdfFilesToRender} selected={selectedPage} />
          </div>
        </>
      ) : (
        <DisclosureAnalysisShimmy />
      )}
      <h3 className="disclosure-viewer-footer">
        Due to the sensitive nature of disclosure documents in a real estate
        transaction, we advise you to conduct further due diligence and we do
        not accept responsibility for any oversight.
      </h3>
    </div>
  );
}

export default DisclosureAnalysisViewer;

function AnalysisModuleViewerWrapper({
  disclosurePackage,
  currentAnalysisModule,
  pdfCache,
  setSelectedPage,
}: {
  disclosurePackage: DisclosurePackage;
  currentAnalysisModule: AnalysisModule | null;
  pdfCache: PDFCache;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const selectedComponent =
    currentAnalysisModule === null
      ? disclosurePackage.analysis.master
      : currentAnalysisModule;

  return (
    <div className="disclosure-viewer-component-analysis">
      <AnalysisModuleViewer
        disclosurePackage={disclosurePackage}
        analysisModule={selectedComponent}
        pdfCache={pdfCache}
        setSelectedPage={setSelectedPage}
      />
    </div>
  );
}
