import "./Nav.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../src/assets/logo.svg"; // Adjust path if needed

const Nav: React.FC = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="pivot-nav">
      {/* Left: Home Link */}
      <Link
        to="/#about-us"
        className="nav-link"
        onClick={(e) => {
          e.preventDefault();
          const aboutSection = document.getElementById("about-us");
          if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        About Us
      </Link>

      {/* Center: Logo */}
      <Link to="/">
        <img src={logo} alt="Logo" className="nav-logo" />
      </Link>

      {/* Right: AI Tools Dropdown */}
      <div
        className="dropdown"
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
      >
        <button className="dropdown-button">AI Tools</button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <ul>
              <Link to="/disclosure/upload" className="dropdown-link">
                Disclosure Analysis
              </Link>
              <li>
                Comparable Sales Analysis <b>(coming soon)</b>
              </li>
              <li>
                Offer Negotiation AI <b>(coming soon)</b>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Nav;
